import {
  setPaymentMethods,
  addPaymentMethod,
  updatePaymentMethod as updatePaymentMethodAction,
  deletePaymentMethod as deletePaymentMethodAction,
  setPrimaryMethod,
} from "redux/actions/paymentMethod.action";
import { request } from "./verb.services";
import { openNotificationWithIcon } from "../utils/Notification";
import { setLoading, setSubmitting } from "redux/actions/loading.actions";

export const getSetupIntent = () => {
  return (dispatch) => {
    // Get the Secret to setup Add Payment Method
    return request(`PaymentMethods/SetupIntent`, "get", null, true)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e.message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const getPublicPaymentIntent = ({ amount, productId }) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    let productIdQs = "";
    let amountQs = "";
    if (productId) {
      productIdQs = `&productId=${productId}`;
    } else if (amount) {
      amountQs = `&amount=${amount}`;
    }
    // Get the Secret to setup Add Payment Method
    return request(
      `PaymentMethods/PaymentIntent?${productIdQs}${amountQs}`,
      "get",
      null,
      true
    )
      .then((response) => {
        return {
          data: response.data,
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",

          e?.response?.data?.message ||
            e.message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPaymentIntent = (data) => {
  return (dispatch) => {
    // Get the Secret to charge Payment Method
    return request("CreatePaymentIntent", "post", data, true)
      .then((response) => response)
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e.message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const postPaymentMethodId = (paymentMethodId) => {
  return (dispatch) => {
    // Get the Secret to charge Payment Method
    return request("PaymentMethods", "post", { paymentMethodId }, true)
      .then((response) => {
        return {
          status: "success",
          response: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e.message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const getAllPaymentMethods = (showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("PaymentMethods", "get", null, true)
      .then((response) => {
        dispatch(setPaymentMethods(response.data));
        return { status: "success", data: response.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e.message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getPaymentMethodById = (
  paymentMethodId,
  showLoading = true,
  callback
) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(`PaymentMethods/${paymentMethodId}`, "get", null, true)
      .then((response) => {
        const result = { status: "success", data: response.data };
        if (callback) {
          callback(result);
        }

        return result;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e.message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const updatePaymentMethod = (paymentMethodId, data) => {
  return (dispatch) => {
    dispatch(setSubmitting(true));
    return request(`PaymentMethods/${paymentMethodId}`, "put", data, true)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Your payment method has been updated successfully."
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e.message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setSubmitting(false)));
  };
};

export const deletePaymentMethod = (paymentMethodId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`PaymentMethods/${paymentMethodId}`, "delete", null, true)
      .then((response) => {
        dispatch(deletePaymentMethodAction(paymentMethodId));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Payment method deleted successfully"
        );
        return { status: "success" };
      })
      .catch((e, ...y) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.[0] ||
            e.message ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const changePrimaryPaymentMethod = (
  paymentMethodId,
  prevPaymentMethodId,
  loading = true
) => {
  return (dispatch) => {
    if (loading) dispatch(setLoading(true));
    return request(
      `PaymentMethods/${paymentMethodId}${
        prevPaymentMethodId
          ? `?oldPrimaryPaymentMethodId=${prevPaymentMethodId}`
          : ""
      }`,
      "patch",
      null,
      true
    )
      .then((response) => {
        // aesa hi ha response
        if (response?.data?.succeeded === false)
          throw new Error("Payment method not found");

        dispatch(setPrimaryMethod(paymentMethodId, prevPaymentMethodId));
        if (loading)
          openNotificationWithIcon(
            "success",
            "Primary Payment Card Updated!",
            "Your primary payment card has been updated successfully"
          );
      })
      .catch((e) => {
        console.log("e", e);
        if (loading)
          openNotificationWithIcon(
            "error",
            "Error!",
            e?.response?.data?.message ||
              e.message ||
              e?.response?.data?.[0] ||
              "Network error has occured"
          );
      })
      .finally(() => {
        if (loading) dispatch(setLoading(false));
      });
  };
};
